import { connect } from 'react-redux';

import { setShowLeftMenu, setDarkMode, setConsideredMedium, setConsideredMobile, setSignedIn } from '../state/actions';

import WindowStateManager from '../components/WindowStateManager';

const mapStateToProps = (state) => ({
    showLeftMenu: state.showLeftMenu,
    darkMode: state.darkMode,
    isConsideredMobile: state.isConsideredMobile,
    consentToCookies: state.consentToCookies,
})
  
const mapDispatchToProps = {
    setShowLeftMenu,
    setDarkMode,
    setConsideredMedium,
    setConsideredMobile,
    setSignedIn,
}
  
const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(WindowStateManager)