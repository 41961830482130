import React, { useCallback } from 'react'
import styled from 'styled-components'

const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;

  color: inherit;

  :hover {
    text-decoration: ${props => (props.$hoverUnderline ? 'underline' : 'none')};
    text-decoration-color: inherit;
  }

  :focus {
    outline: none;
    text-decoration: none;
  }

  :active {
    text-decoration: none;
  }
`

const ExternalLink = ({
    target = '_blank',
    href,
    rel = 'noopener noreferrer',
    fullWidth = true,
    hoverUnderline = false,
    ...rest
  }) => {
    const handleClick = useCallback(
      (event) => {
        // don't prevent default, don't redirect if it's a new tab
        if (target === '_blank' || target === '_self' || event.ctrlKey || event.metaKey) {
            // Nothing
        } else {
          event.preventDefault()
        }
      },
      [target]
    )
    return <StyledLink $hoverUnderline={hoverUnderline} style={{...(fullWidth && { width: '100%' })}} target={target} rel={rel} href={href} onClick={handleClick} {...rest} />
}

export default ExternalLink;