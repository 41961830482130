import numeral from "numeral";
import { MerkleTree } from 'merkletreejs';
import { utils } from 'ethers';
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

// import { signOutClearAuthTokens } from '../services/auth';

export const centerShortenLongString = (string, maxLength) => {
	if(typeof string === 'string') {
		if(string.length > maxLength) {
			let charCountForRemoval = string.length - maxLength;
			let stringHalfwayPoint = Math.floor(maxLength/2);
			string = string.slice(0, stringHalfwayPoint) + "..." + string.slice(stringHalfwayPoint + charCountForRemoval, string.length);
			return string;
		}else{
			return string;
		}
	}else{
		return '';
	}
}

export const toChecksumAddress = (string) => {
	let checksumHolderAddress = string;
	try {
		checksumHolderAddress = utils.getAddress(string);
	} catch (error) {
		return string;
	}
	return checksumHolderAddress;
}

export const APP_CONFIG_ALL_ENVS = {
 "development": {
		PROJECT_ROOT: "/home",
		API_BASE_URL: "https://dev.api.propy.com/api/",
		API_TP_BASE_URL: "https://dev.tp.propy.com/api/",
		API_IDENTITY_SERVER_URL: "https://dev.is.propy.com",
		API_IDENTITY_SERVICE_CLIENT_ID: "propy-web",
		BASE_URL: "https://dev.propy.com",
		ONBOARDING_ROOT: "https://dev.propy.com/onboarding",
    GOOGLE_ANALYTICS_ID: "UA-110127661-1",
    GOOGLE_GTAG_ID: "GTM-NJL65DN",
 },
 "stage": {
		PROJECT_ROOT: "/home",
		API_BASE_URL: "https://stage.api.propy.com/api/",
		API_TP_BASE_URL: "https://stage.tp.propy.com/api/",
		API_IDENTITY_SERVER_URL: "https://stage.is.propy.com",
		API_IDENTITY_SERVICE_CLIENT_ID: "propy-web",
		BASE_URL: "https://stage.propy.com",
		ONBOARDING_ROOT: "https://stage.propy.com/onboarding",
    GOOGLE_ANALYTICS_ID: "UA-65716380-5",
    GOOGLE_GTAG_ID: "GTM-NJL65DN",
    SMARTLOOK_ID: "6bb5a0bdab863c838a4ecf0000364eec693fa91e",
	},
 "production": {
		PROJECT_ROOT: "/home",
		API_BASE_URL: "https://api.propy.com/api/",
		API_TP_BASE_URL: "https://tp.propy.com/api/",
		API_IDENTITY_SERVER_URL: "https://is.propy.com",
		API_IDENTITY_SERVICE_CLIENT_ID: "propy-web",
		BASE_URL: "https://propy.com",
		ONBOARDING_ROOT: "https://propy.com/onboarding",
    GOOGLE_ANALYTICS_ID: "G-TPR0EZ4CBK",
    GOOGLE_GTAG_ID: "GTM-NJL65DN",
    SMARTLOOK_ID: "6bb5a0bdab863c838a4ecf0000364eec693fa91e",
	}
}

export const APP_CONFIG = APP_CONFIG_ALL_ENVS[process.env.GATSBY_ENV ? process.env.GATSBY_ENV : "development"];

export const ONBOARDING_ROOT = APP_CONFIG.ONBOARDING_ROOT;
export const PROJECT_ROOT = APP_CONFIG.PROJECT_ROOT; // using "/home" while site is on dev.propy.com/home/
export const API_BASE_URL = APP_CONFIG.API_BASE_URL;
export const API_TP_BASE_URL = APP_CONFIG.API_TP_BASE_URL;
export const API_IDENTITY_SERVER_URL = APP_CONFIG.API_IDENTITY_SERVER_URL;
export const API_IDENTITY_SERVICE_CLIENT_ID = APP_CONFIG.API_IDENTITY_SERVICE_CLIENT_ID;
export const BASE_URL = APP_CONFIG.BASE_URL;
export const WORDPRESS_URL = "https://propy.com";

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const PROPY_LIGHTER_GREY = "#AAACBA";

export const PROPY_LIGHT_GREY = "#f6f6f6";

export const PROPY_SPACE_GREY_LIGHT = "#696B84";

export const PROPY_SPACE_GREY_SECONDARY = "#394461";

export const PROPY_SPACE_GREY_DARK = "#333333";

export const PROPY_BLACK = "#000000";

export const PROPY_WHITE = "#FFFFFF";

export const PROPY_LIGHT_BLUE = "#38A6FB";

export const PROPY_FADED_GREY = "#E8EAEF";

export const PROPY_BLUE = "#38a6fa";
export const PROPY_BLUE_DARK = "#007BDA";

export const IS_GLOBAL_TOP_BANNER_ENABLED = false;
export const GLOBAL_TOP_BANNER_HEIGHT = 28;
export const GLOBAL_PAGE_HEIGHT = IS_GLOBAL_TOP_BANNER_ENABLED ? `calc(100vh - ${64 + GLOBAL_TOP_BANNER_HEIGHT}px)` : 'calc(100vh - 64px)';

export const shuffleArray = (array) => {
  const shuffled = [...array];
  
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  
  return shuffled;
}

export function extractPropertyLotSize(data, returnType) {
	let result = {
		value: Math.floor(data?.value),
		label: "",
	}
	if(data?.type === 1) {
		result.label = "ft²"
	}
	if(returnType) {
		return result[returnType]
	}
	return result
}

const getDynamicFormat = (currentFormat = '0,0.00', number) => {
	let requestedDecimals = 0;
	let preDecimalFormat;
	let postDecimalFormat;
	if(currentFormat.split(".").length > 0) {
			requestedDecimals = currentFormat.split(".")[1].length;
			postDecimalFormat = currentFormat.split(".")[1];
			preDecimalFormat = currentFormat.split(".")[0];
	}
	let currentFormattedNumber = numeral(number).format(currentFormat).toString();
	let currentFormattedDecimals = '';
	if(currentFormattedNumber.split('.') && currentFormattedNumber.split('.')[1]) {
			currentFormattedDecimals = currentFormattedNumber.split('.')[1];
	}
	let currentUnformattedDecimals = '';
	if(number.toString().split(".").length > 0 && number.toString().split(".")[1]) {
			currentUnformattedDecimals = number.toString().split(".")[1];
	}
	let dynamicFormat = currentFormat;
	if((currentFormattedDecimals.replace(/[^1-9]/g,"").length < requestedDecimals) && (currentUnformattedDecimals.replace(/[^1-9]/g,"").length >= requestedDecimals)) {
			let indexOfSignificantFigureAchievement = 0;
			let significantFiguresEncountered = 0;
			let numberString = number.toString();
			let numberStringPostDecimal = "";
			if(numberString.split(".").length > 0) {
					numberStringPostDecimal = numberString.split(".")[1]
			}
			for(let i = 0; i < numberStringPostDecimal.length; i++) {
					// @ts-ignore
					if((numberStringPostDecimal[i] * 1) > 0) {
							significantFiguresEncountered++;
							if(significantFiguresEncountered === requestedDecimals) {
									indexOfSignificantFigureAchievement = i + 1;
							}
					}
			}
			if(indexOfSignificantFigureAchievement > requestedDecimals) {
					let requestedDecimalsToSignificantFiguresDelta = indexOfSignificantFigureAchievement - requestedDecimals;
					dynamicFormat = preDecimalFormat + ".";
					if(postDecimalFormat) {
							dynamicFormat = preDecimalFormat + "." + postDecimalFormat;
					}
					for(let i = 0; i < requestedDecimalsToSignificantFiguresDelta; i++) {
							dynamicFormat = dynamicFormat + "0";
					}
			}
	}
	return dynamicFormat;
}

export const priceFormat = (number, decimals = 2, currency = "$", prefix = true) => {
	let decimalString = "";
	for(let i = 0; i < decimals; i++){
			decimalString += "0";
	}
	if (currency.length > 1) {
			prefix = false;
	}
	let format = '0,0.' + decimalString;
	if(Number(number) < 10) {
			format = getDynamicFormat(format, number);
	}
	let result = numeral(number).format(format);
	if(result === 'NaN') {
		result = '0.00';
	}
	if (prefix) {
			return `${currency}${'\u00A0'}`+ result;
	} else {
			return result + `${'\u00A0'}${currency}`
	}
}

export const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";SameSite=Strict;path=/";
}

export const getCookieValue = (name) => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

export const uuid = () => {
	/*eslint-disable */
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
	/*eslint-enable */
}

export const trackCustomEvent = ({
  category,
  action,
  label,
  value
}) => {
	try {
		if (typeof window !== 'undefined' && window.gtag) {
			window.gtag('event', action, {
				event_category: category,
				event_label: label,
				value: value
			})
			
			if (process.env.GATSBY_ENV === 'development') {
				console.log('Event tracked:', {
					category,
					action,
					label,
					value
				})
			}
		}
	} catch (e) {
		console.log("Error tracking event");
	}
}

export const getRandomElement = (array) => {
	if (!array || array.length === 0) {
			return null;
	}
	return array[Math.floor(Math.random() * array.length)];
}

export const merkleTreeGenerator = async (whitelist) => {
  const leaves = Object.entries(whitelist).map((x) =>  {
    return utils.solidityKeccak256(["address", "uint256"], [x[0], x[1]]);
  });
  const tree = new MerkleTree(leaves, utils.keccak256, { sortPairs: true });
  const root = tree.getRoot().toString('hex');
  return '0x' + root;
}

export const merkleTreeGenerateProof = async (whitelist, ethAddress, amount) => {
  const leaves = Object.entries(whitelist).map((x) =>  {
    return utils.solidityKeccak256(["address", "uint256"], [x[0], x[1]]);
  });
  const tree = new MerkleTree(leaves, utils.keccak256, { sortPairs: true });
  const leaf = utils.solidityKeccak256(["address", "uint256"], [ethAddress, amount]);
  const proof = tree.getHexProof(leaf);
  return proof;
}

export const NETWORK_ID_TO_NAME = {
	1: 'ethereum',
	42161: 'arbitrum',
  8453: 'base',
  ...(process.env.GATSBY_ENV !== 'production' && {
    84532: 'base-sepolia',
    84531: 'base-goerli',
    5: 'goerli',
    11155111: 'sepolia',
  })
}

export const NETWORK_ID_TO_RPC = {
  1: (process.env.GATSBY_APP_QUICKNODE_RPC_URL_MAINNET) ? process.env.GATSBY_APP_QUICKNODE_RPC_URL_MAINNET : "",
  42161: (process.env.GATSBY_APP_QUICKNODE_RPC_URL_ARBITRUM) ? process.env.GATSBY_APP_QUICKNODE_RPC_URL_ARBITRUM : "",
  8453: (process.env.GATSBY_APP_QUICKNODE_RPC_URL_BASE) ? process.env.GATSBY_APP_QUICKNODE_RPC_URL_BASE : "",
  ...(process.env.GATSBY_ENV !== 'production' && {
    84532: process.env.GATSBY_APP_QUICKNODE_RPC_URL_BASE_SEPOLIA ? process.env.GATSBY_APP_QUICKNODE_RPC_URL_BASE_SEPOLIA : "",
    11155111: process.env.GATSBY_APP_QUICKNODE_RPC_URL_SEPOLIA ? process.env.GATSBY_APP_QUICKNODE_RPC_URL_SEPOLIA : "",
  })
}

export const NETWORK_NAME_TO_DISPLAY_NAME = {
	'ethereum': 'Ethereum',
	'arbitrum': 'Arbitrum',
  'base': 'Base',
  ...(process.env.GATSBY_ENV !== 'production' && {
    'base-sepolia': 'Base Sepolia',
    'base-goerli': 'Base Goerli',
    'goerli': "Goerli",
    'sepolia': 'Sepolia'
  })
}

export const NETWORK_NAME_TO_ID = {
	'ethereum': 1,
	'arbitrum': 42161,
  'base': 8453,
  ...(process.env.GATSBY_ENV !== 'production' && {
    'base-sepolia': 84532,
    'base-goerli': 84531,
    'goerli': 5,
    'sepolia': 11155111,
  })
}

const ETHERSCAN_PREFIXES_CHAIN_ID = {
	1: 'etherscan.io',
	3: 'ropsten.etherscan.io',
	4: 'rinkeby.etherscan.io',
	5: 'goerli.etherscan.io',
	42: 'kovan.etherscan.io',
	100: '',
	1337: '',
	56: '',
	97: '',
	137: '',
	361: '',
	365: '',
	1285: '',
	80001: '',
	1666600000: '',
	11297108109: '',
	31337: '',
	250: '',
	43114: '',
	19: '',
	1287: '',
	25: '',
	338: '',
	1284: '',
	42262: '',
	588: '',
	69: '',
	10: '',
	42161: 'arbiscan.io',
	421611: '',
	11155111: 'sepolia.etherscan.io',
	84532: 'sepolia.basescan.org',
	8453: 'basescan.org',
}

export function getEtherscanLinkByChainId(
	chainId,
	data,
	type, // 'transaction' | 'token' | 'address' | 'block'
) {
	const prefix = `https://${ETHERSCAN_PREFIXES_CHAIN_ID[chainId] || ETHERSCAN_PREFIXES_CHAIN_ID[1]}`

	switch (type) {
		case 'transaction': {
		return `${prefix}/tx/${data}`
		}
		case 'token': {
		return `${prefix}/token/${data}`
		}
		case 'block': {
		return `${prefix}/block/${data}`
		}
		case 'address':
		default: {
		return `${prefix}/address/${data}`
		}
	}
}

export const ETHERSCAN_PREFIXES_NETWORK_NAME = {
	'ethereum': 'etherscan.io',
	'ropsten': 'ropsten.etherscan.io',
	'rinkeby': 'rinkeby.etherscan.io',
	'goerli': 'goerli.etherscan.io',
	'arbitrum': 'arbiscan.io',
	'sepolia': 'sepolia.etherscan.io',
	'base-sepolia': 'sepolia.basescan.org',
	'base': 'basescan.org'
}

export const debounce = (func, wait = 150) => {
  let timeout;
  
  const debouncedFn = function(...args) {
    const context = this;
    
    if (timeout) {
      clearTimeout(timeout);
    }
    
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
  
  debouncedFn.cancel = function() {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };
  
  return debouncedFn;
};
  
export function getEtherscanLinkByNetworkName(
	networkName,
	data,
	type // 'transaction' | 'token' | 'address' | 'block'
) {
	const prefix = `https://${ETHERSCAN_PREFIXES_NETWORK_NAME[networkName] || ETHERSCAN_PREFIXES_NETWORK_NAME['ethereum']}`

	switch (type) {
		case 'transaction': {
		return `${prefix}/tx/${data}`
		}
		case 'token': {
		return `${prefix}/token/${data}`
		}
		case 'block': {
		return `${prefix}/block/${data}`
		}
		case 'address':
		default: {
		return `${prefix}/address/${data}`
		}
	}
}

export const MENU_ENTRIES = [
  {
		featuredEntry: true,
    label: "Buyer",
    id: 'buyer',
    desktopFlyoutTitle: "Buy with Propy",
    desktopFlyoutDescription: "Purchase your next home with next-generation tech and agents.",
    desktopFlyoutHeight: 180,
    ctaLink: `${ONBOARDING_ROOT}/get-started/buyer`,
    ctaText: "I'm a buyer",
    innerEntries: [
      [
        {
          label: 'Title & Escrow',
          link: `${WORDPRESS_URL}/browse/propytitle/`,
          external: true,
          featuredEntry: true,
        },
        {
          label: "Crypto Escrow with Coinbase",
          link: "https://coinbase.propy.com/escrow/?q=homepage_menu",
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Browse All Listings',
          link: `${BASE_URL}/map`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Featured Listings',
          link: `${BASE_URL}/featured`,
          external: true,
        },
      ],
      [
				{
          label: 'Find an Agent',
          link: `${BASE_URL}/search`,
          external: true,
        },
        {
          label: 'Pricing',
          link: `${WORDPRESS_URL}/browse/subscriptions/`,
          external: true,
        },
        {
          label: 'Transaction Platform',
          link: `${WORDPRESS_URL}/browse/transaction-platform/`,
          external: true,
        },
        {
          label: 'Offer Management',
          link: `${WORDPRESS_URL}/browse/real-estate-offer-management/`,
          external: true,
        },
      ],
    ]
  },
  {
		featuredEntry: true,
    label: "Seller",
    id: 'seller',
    desktopFlyoutTitle: "Sell with Propy",
    desktopFlyoutDescription: "Promote your listing to the Propy community - close fast & securely, in USD or crypto.",
    desktopFlyoutHeight: 200,
    ctaLink: `${ONBOARDING_ROOT}/get-started/seller`,
    ctaText: "I'm a seller",
    innerEntries: [
      [
        {
          label: 'Title & Escrow',
          link: `${WORDPRESS_URL}/browse/propytitle/`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'List A Property',
          link: `${BASE_URL}/list-property`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'View My Listings',
          link: `${BASE_URL}/settings?selectedTab=MY_LISTINGS`,
          external: true,
        },
        {
          label: 'Sell as an NFT',
          link: `${WORDPRESS_URL}/browse/real-estate-nft/`,
          external: true,
        },
				{
          label: 'Webinars',
          link: `${WORDPRESS_URL}/browse/webinars/`,
          external: true,
        },
      ],
      [
				{
          label: 'Pricing',
          link: `${WORDPRESS_URL}/browse/subscriptions/`,
          external: true,
        },
        {
          label: 'Transaction Platform',
          link: `${WORDPRESS_URL}/browse/transaction-platform/`,
          external: true,
        },
        {
          label: 'Offer Management',
          link: `${WORDPRESS_URL}/browse/real-estate-offer-management/`,
          external: true,
        },
      ],
    ]
  },
  {
		featuredEntry: true,
    label: "Agent",
    id: 'agent',
    desktopFlyoutTitle: "Buy & Sell with Propy",
    desktopFlyoutDescription: "Our products cater to real estate buyers, sellers & agents",
    desktopFlyoutHeight: 324,
    ctaLink: `${ONBOARDING_ROOT}/get-started/agent`,
    ctaText: "I'm an agent",
    innerEntries: [
      [
        {
          label: 'List A Property',
          link: `${BASE_URL}/list-property`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Buy A Property',
          link: `${BASE_URL}/map`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Leaderboard',
          link: `${ONBOARDING_ROOT}/agent-leaderboard`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Net Sheet Calculator',
          link: `${BASE_URL}/quote`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Browse All Listings',
          link: `${BASE_URL}/map`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Title & Escrow',
          link: `${WORDPRESS_URL}/browse/propytitle/`,
          external: true,
          featuredEntry: true,
        },
        {
          label: 'Record Blockchain Deed',
          link: `${BASE_URL}/tp/title-deed`,
          external: true,
        },
        {
          label: 'Membership',
          link: `${WORDPRESS_URL}/subscriptions/`,
          external: true,
        },
        {
          label: 'Earn Propy Tokens',
          link: `${WORDPRESS_URL}/browse/rewards/`,
          external: true,
        },
				{
          label: 'FAQ',
          link: `${WORDPRESS_URL}/browse/faq/`,
          external: true,
        },
        {
          label: 'Platform features',
          link: `${WORDPRESS_URL}/browse/newfeatures`,
          external: true,
        },
        {
          label: 'Download Propy App',
          link: `https://apps.apple.com/us/app/propy-real-estate-automated/id1017369540`,
          external: true,
        },
      ],
      [
        {
          label: 'Transaction Platform',
          link: `${WORDPRESS_URL}/browse/transaction-platform/`,
          external: true,
        },
        {
          label: 'Offer Management',
          link: `${WORDPRESS_URL}/browse/real-estate-offer-management/`,
          external: true,
        },
        {
          label: 'Get Crypto Certified',
          link: `${WORDPRESS_URL}/browse/crypto-for-real-estate/`,
          external: true,
        },
        {
          label: 'Get Meta Certified',
          link: `${WORDPRESS_URL}/browse/meta-agent/`,
          external: true,
        },
        {
          label: 'Propy Blog',
          link: `${WORDPRESS_URL}/browse/blog/`,
          external: true,
        },
        {
          label: 'Webinars',
          link: `${WORDPRESS_URL}/browse/webinars/`,
          external: true,
        },
				{
          label: '"How To" Guides',
          link: `${WORDPRESS_URL}/browse/knowledge-base/`,
          external: true,
        },
        {
          label: 'Case Studies',
          link: `${WORDPRESS_URL}/browse/case-studies/`,
          external: true,
        },
        {
          label: 'Newsletter',
          link: `${WORDPRESS_URL}/browse/newsletter/`,
          external: true,
        },
      ],
    ]
  },
  {
		featuredEntry: true,
    label: "Title & Escrow",
    id: 'title-and-escrow',
    // img: TitleAndEscrowIcon,
    external: true,
    link: "https://propy.com/browse/propytitle/"
  },
	{
		featuredEntry: false,
    label: "Open Escrow",
    id: 'open-escrow',
    external: true,
    link: `${ONBOARDING_ROOT}/drop-contract`,
    hideHeroInvisibleSignedOut: true,
  },
  {
		featuredEntry: false,
    label: "Sign In",
    id: 'sign-in',
    external: true,
    link: `${BASE_URL}/login`,
    hideSignedIn: true,
    hideHeroInvisibleSignedOut: true,
  },
  {
    featuredEntry: false,
    label: "Go to Platform",
    id: 'go-to-platform',
    external: true,
    link: `${BASE_URL}/dashboard`,
    hideSignedOut: true,
  },
  // {
  //   featuredEntry: false,
  //   label: "Sign Out",
  //   id: 'sign-out',
  //   hideSignedOut: true,
  //   onClick: () => signOutClearAuthTokens(),
  // }
]
 
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const parseJwt = (token) => {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
			window
					.atob(base64)
					.split('')
					.map(function (c) {
							return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
	);

	return JSON.parse(jsonPayload);
}